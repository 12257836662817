import React from 'react'
import AppLayout from '../components/layouts/appLayout'
import { HeadingContainer } from '../components/common/typography'
import SEO from '../components/seo'
import styled from '@emotion/styled'
import PdfFile from '../pdf/Operoute_Brochure.pdf';

const Container = styled.div`
  display: grid;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 6rem;
  box-sizing: border-box;
`
const PdfPage = () => {
  return (
    <AppLayout>
      <SEO title="Operoute" canonical="operoute" />
      <HeadingContainer title="Operoute" subtitle="" />
      <Container>
        <iframe title="Openrute" src={PdfFile} width="100%"  height="650px"/>
      </Container>  
    </AppLayout>
  )
}

export default PdfPage;